// images
import blogImg1 from "../images/blog/blog_post_image_1.webp";
import blogImg2 from "../images/blog/blog_post_image_2.png";
import blogImg3 from "../images/blog/blog_post_image_3.png";
import blogImg4 from "../images/blog/blog_post_image_4.png";
import blogImg5 from "../images/blog/blog_post_image_5.png";
import blogImg6 from "../images/blog/blog_post_image_6.png";
import blogImg7 from "../images/blog/blog_post_image_7.png";
import blogImg8 from "../images/blog/blog_post_image_8.png";

// images
import blogsImg1 from "../images/blog/blog_post_image_1.webp";
import blogsImg2 from "../images/blog/blog_post_image_2.png";
import blogsImg3 from "../images/blog/blog_post_image_3.png";
import blogsImg4 from "../images/blog/blog_post_image_4.png";


const blogs = [
    {
        id: '1',
        title: 'Leveraging the power of Solar Energy towards Sustainable Growth & Investment.',
        slug:'Leveraging-Analytics-for-Business-Growth-Modernizing',
        screens: blogImg1,
        bSingle: blogsImg1,
        description: 'Use high-quality images and videos to create a visually appealing experience. Visuals of happy clients can significantly...',
        author: 'farugia',
        authorTitle:'Sineor Consultant',
        create_at: '11/12/2024',
        comment:'35',
        thumb:'Branding',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Shree Ram Murti Smarak:',
        slug:'How-Emerging-Technologies-are-Shaping-the-Future',
        screens: blogImg2,
        bSingle: blogsImg2,
        description: '1 MW of solar panels installed to support energy needs sustainably.',
        author: 'Andrew',
        authorTitle:'Creative Director',
        create_at: '11/02/2024',
        comment:'80',
        thumb:'Mobile App',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'B.L. Agro: ',
        slug: 'Creating-Engaging-Digital-for-Your-Audience-Software',
        screens: blogImg3,
        bSingle: blogsImg3,
        description: 'A 740 KW solar plant ensuring efficient and eco-friendly operations',
        author: 'Kurtz',
        authorTitle:'Art Director',
        create_at: '21/08/2024',
        comment:'95',
        thumb:'Technology',
        blClass:'format-video',
    },
    {
        id: '4',
        title: 'IFFCO: ',
        slug: 'How-Our-Software-Solutions-Drive-Insights.',
        screens: blogImg4,
        bSingle: blogsImg4,
        description: '405 KW solar power installation, paving the way for sustainable manufacturing.',
        author: 'Alex',
        authorTitle:'Art Director',
        create_at: '18 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '5',
        title: 'GRM School:',
        slug: 'Exploring-Emerging-Trends-in-Software-Development.',
        screens: blogImg5,
        bSingle: blogsImg4,
        description: ': 32 KW system empowering education with renewable energy.',
        author: 'Aliza',
        authorTitle:'Art Director',
        create_at: '25 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '6',
        title: 'Shri Siddhi Vinayak Trust:',
        slug: 'How-Software-Integration-Can-Improve-Workflow.',
        screens: blogImg6,
        bSingle: blogsImg4,
        description: ': A robust 460 KW solar solution for energy self-sufficiency.',
        author: 'Tika',
        authorTitle:'Art Director',
        create_at: '17 / 08 / 2024',
        comment:'95',
        thumb:'Consulting',
    },
    {
        id: '7',
        title: 'Vrinda Exim:',
        slug: '5-ways-to-relieve-stress-during-the-holidays',
        screens: blogImg7,
        bSingle: blogsImg4,
        description: ': 91 KW solar plant contributing to cleaner energy practices',
        author: 'Riley Steinmetz',
        authorTitle:'Art Director',
        create_at: 'April 20, 2024',
        comment:'95',
        thumb:'Live Well',
    },
    {
        id: '8',
        title: 'C.L. Gupta Exports: ',
        slug: 'Baby-flat-head-pillow-why-its-important',
        screens: blogImg8,
        bSingle: blogsImg4,
        description: '270 KW installation driving industrial sustainability',
        author: 'Ken William',
        authorTitle:'Art Director',
        create_at: 'April 24, 2024',
        comment:'95',
        thumb:'Get Well',
    },
];
export default blogs;