import React from 'react'
import { Link } from 'react-router-dom'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const BusinessFooter = (props) => {
    return (
        <footer className="site_footer footer_layout_3">
        <div className="footer_main_content bg-dark">
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className="heading_block mb-lg-0 text-white">
                  <h2 className="heading_text mb-1">
                    How may we help you?
                  </h2>
                  <p className="heading_description mb-0 text-uppercase">
                    Let's collaborate and go Solar.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="social_wrap">
                  <h3 className="footer_info_title text-white mb-4 mb-md-0">Follow Us</h3>
                  <ul className="social_icons_block unordered_list">
                    <li>
                      <Link onClick={ClickHandler} to="/home_business_consulting">
                        <i className="fa-brands fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/home_business_consulting">
                        <i className="fa-brands fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/home_business_consulting">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/home_business_consulting">
                        <i className="fa-brands fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row justify-content-lg-between pt-lg-4">
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Start a Conversation</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <a href="mailto:jasoriabrother@gmail.com">
                            <span className="icon_list_text">
                              jasoriabrother<br/>@gmail.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Call Us:</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <a href="tel:+91-7830000307">
                            <span className="icon_list_text">
                              7830000307
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget">
                  <h3 className="footer_info_title">Useful Links</h3>
                  <ul className="icon_list unordered_list_block">
                    <li>
                      <Link onClick={ClickHandler} to="/service">
                        <span className="icon_list_text">
                          Careers
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/team">
                        <span className="icon_list_text">
                          Our Team
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/portfolio">
                        <span className="icon_list_text">
                          Latest Projects
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/portfolio">
                        <span className="icon_list_text">
                          Awarded portfolio
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        <span className="icon_list_text">
                          FAQ
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="footer_widget">
                  <h3 className="footer_info_title">Address</h3>
                  <ul className="icon_list unordered_list_block">
                    <li>
                      <Link onClick={ClickHandler} to="https://www.google.co.in/maps/dir//Shop+number+30,+opposite,+nagar+negam,+Civil+Lines,+Bareilly,+Uttar+Pradesh+243001/@28.3555881,79.3373621,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39a0072b618f46f7:0x92752a4404b87b8b!2m2!1d79.4197635!2d28.3556129?entry=ttu&g_ep=EgoyMDI0MTIwOC4wIKXMDSoASAFQAw%3D%3D">
                        <span className="icon_list_text">
                        Shop number 30, opposite, nagar negam, Civil Lines, Bareilly, Uttar Pradesh 243001
                        </span>
                      </Link>
                    </li>
                    <li>
                      {/* <Link onClick={ClickHandler} to="/home_business_consulting">
                        <span className="icon_list_text">
                          201 Spear Street,
                        </span>
                      </Link> */}
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/home_business_consulting">
                        <span className="icon_list_text">
                          San Francisco, CA, USA
                        </span>
                      </Link> */}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom bg-primary text-white">
          <div className="container d-md-flex align-items-md-center justify-content-md-between">
            <p className="copyright_text m-0">
              Developed by Sasahyog Technologies Pvt Ltd
            </p>
            <p className="copyright_text m-0">
              Website <Link onClick={ClickHandler} to="/" target="_blank">www.sasahyog</Link>
            </p>
          </div>
        </div>
      </footer>
    )
}

export default BusinessFooter;